import React from "react";
import ExportHeaderImage from "../../../assets/images/logos/underwood/mature-soybeans.png";
import Elevator from "../../../assets/images/logos/underwood/elevator.svg";
import WhiteBeans from "../../../assets/images/logos/underwood/White_Beans.JPG";
import BlackBean from "../../../assets/images/logos/underwood/Black_Beans.JPG";
import CIPRS from "../../../assets/images/logos/underwood/ciprs.svg";
import HACCP from "../../../assets/images/logos/underwood/haccp.svg";
import UnderwoodCommodityCard from "./components/commodityCard";

// Commodity beans
import AdzukiBeans from "../../../assets/images/logos/underwood/beans/adzuki-beans.png";
import NavyBeans from "../../../assets/images/logos/underwood/beans/navy-beans.png";
import BlackBeans from "../../../assets/images/logos/underwood/beans/black-beans.png";
import SmallRedBeans from "../../../assets/images/logos/underwood/beans/red-beans.png";

function Export() {
  return (
    <div className="w-full h-full mt-[100px] flex flex-col">
      <div
        className="relative flex flex-col justify-center items-center h-[400px] w-full bg-cover bg-center bg-no-repeat text-white"
        style={{
          backgroundImage: `url(${ExportHeaderImage})`,
        }}
      >
        <div className="absolute inset-0 bg-black opacity-40"></div>
        <h1 className="relative z-10 text-3xl md:text-4xl font-bold text-center mb-3">
          Export Partners
        </h1>
        <p className="relative z-10 text-base md:text-xl text-center w-11/12 md:w-2/3">
          At Underwood Grain, our goal is to produce high quality edible beans
          and food-grade soybeans that exceed customer expectations. Through
          dedicated efforts to maximize bean colour and purity, we pride
          ourselves on delivering beans to buyers around the world for direct
          consumption and processing.
        </p>
      </div>
      <div className="w-full h-full p-4 md:p-20">
        <p className="text-xl md:text-2xl font-semibold text-center">
          We offer several market classes of edible beans and food-grade
          soybeans including:
        </p>
        <div className="flex flex-wrap justify-center gap-6 mt-10">
          <UnderwoodCommodityCard title={"Navy/White"} iconPath={NavyBeans} />
          <UnderwoodCommodityCard title={"Black"} iconPath={BlackBeans} />
          <UnderwoodCommodityCard
            title={"Small Red"}
            iconPath={SmallRedBeans}
          />
          <UnderwoodCommodityCard title={"Adzuki"} iconPath={AdzukiBeans} />
        </div>
        <TableComponent />
        <div className="flex flex-col md:flex-row justify-center items-center gap-6 p-4 md:p-10 mt-10">
          <img
            src={BlackBean}
            alt="bean"
            className="w-full md:max-w-[478px] h-auto rounded-md"
          />
          <div className="flex flex-col text-left">
            <h1 className="text-2xl md:text-xl xl:text-4xl lg:text-3xl font-bold text-[#2A66B2]">
              Field Production
            </h1>
            <p className="text-base mt-4 xl:text-xl">
              Underwood Grain is fortunate to have excellent edible bean and
              food-grade soybean growers who understand the value and importance
              of producing quality beans. By focusing on quality from seeding to
              harvest, our producers grow beans that they feel just as confident
              feeding to their own families as they would feeding families
              around the world. By following procedures laid out by Underwood
              Grain, including the use of certified seed, equipment cleanout
              checklists, on-farm inspections, and segregated receiving and
              storage facilities, the producers together with Underwood Grain
              can ensure that the beans destined for processing reach our
              facility in optimal quality.
            </p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row-reverse justify-center items-center bg-[#E7F2FF] gap-6 p-4 md:p-10 mt-10">
          <img
            src={WhiteBeans}
            alt="beans"
            className="w-full md:max-w-[478px] h-auto rounded-md"
          />
          <div className="flex flex-col md:text-right">
            <h1 className="text-2xl md:text-xl xl:text-4xl lg:text-3xl font-bold text-[#2A66B2]">
              Processing & Quality Assurance
            </h1>
            <p className="text-base mt-5 xl:text-xl">
              Food Safety & Quality Statement: We are committed to continual
              improvement and exceeding customer satisfaction by safely and
              efficiently providing the highest quality products and services.
            </p>
            <p className="text-base mt-4 xl:text-xl">
              Underwood Grain employs a number of policies and procedures,
              together with industry certifications to monitor and optimize the
              quality of beans we deliver to our buyers across the country and
              around the world. Underwood Grain is a licensed grain elevator,
              holding HACCP and CIPRS certifications. We are also certified
              under the BRCGS START! Programme for soybean and edible bean
              receiving and processing.
            </p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-center items-center gap-6 p-4 md:p-10 mt-10">
          <img
            src={Elevator}
            alt="elevator"
            className="w-full md:max-w-[478px] h-auto rounded-md"
          />
          <div className="flex flex-col text-left">
            <h1 className="text-2xl md:text-xl xl:text-4xl lg:text-3xl font-bold text-[#2A66B2]">
              Processing Journey
            </h1>
            <p className="text-base mt-4 xl:text-xl">
              When beans arrive at Underwood Grain, they are first sampled for
              moisture and quality, and a sample is retained. The beans are then
              sent to one of our dedicated IP soybean or edible receiving
              stations where the beans are segregated into dedicated silos based
              on market class or variety. Once processing begins, the beans
              first reach our air-screen cleaner before passing across a
              destoner, gravity table, and finally an optical colour sorter.
              Throughout processing, the beans pass through in-line magnets
              several times, with additional magnets positioned prior to final
              packaging. Quality checks are performed constantly to ensure a
              consistent product quality is achieved over the course of
              processing. 
              <br />
              <br />
              Underwood Grain offers a variety of packaging options, including
              bagging in poly or paper material, totes, or bulk loading. We are
              also able to load both shipping containers and van trailers to
              meet your logistics and shipping needs.
            </p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-center items-center p-4 md:p-20 mt-10 gap-6">
          <img
            src={CIPRS}
            alt="ciprs"
            className="w-[265px] h-auto rounded-md"
          />
          <img
            src={HACCP}
            alt="haccp"
            className="w-[265px] h-auto rounded-md"
          />
        </div>
      </div>
    </div>
  );
}

const TableComponent = () => {
  const data = [
    { variety: "S03-W4", hilumColour: "IY", protein: 41 },
    { variety: "S04-K9", hilumColour: "Y", protein: 43 },
    { variety: "S07-M8", hilumColour: "IY", protein: 42 },
    { variety: "S12-J7", hilumColour: "Y", protein: 43 },
    { variety: "OAC Strive", hilumColour: "IY", protein: 42 },
  ];

  return (
    <div className="overflow-x-auto mt-10 border border-blue-200">
      <table className="min-w-full">
        <thead>
          <tr className="bg-blue-200">
            <th className="p-3 text-left font-semibold border border-blue-200">
              Variety
            </th>
            <th className="p-3 text-left font-semibold border border-blue-200">
              Hilum Colour
            </th>
            <th className="p-3 text-left font-semibold border border-blue-200">
              Protein %
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td className="p-3 border border-blue-200">{item.variety}</td>
              <td className="p-3 border border-blue-200">{item.hilumColour}</td>
              <td className="p-3 border border-blue-200">{item.protein}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Export;
